<template>
  <div class="settings-toolbar">
    <!-- Reset -->
    <el-button size="large" :disabled="resetDisabledLocal" @click="handleReset">
      {{ $t('pages.settings.toolbar.button.reset') }}
    </el-button>

    <div class="flex-grow" />

    <!-- Save -->
    <el-button
      type="primary"
      size="large"
      :loading="loading"
      :disabled="saveDisabledLocal"
      @click="handleSave"
    >
      {{ $t('common.interactions.buttons.save') }}
    </el-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SettingsToolbar',
  props: {
    resetDisabled: {
      type: Boolean,
      default: undefined
    },
    saveDisabled: {
      type: Boolean,
      default: undefined
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      isDirty: 'Config/getClientAccountConfigurationIsDirty'
    }),
    saveDisabledLocal() {
      return this.saveDisabled !== undefined
        ? this.saveDisabled
        : this.isDirty !== true
    },
    resetDisabledLocal() {
      return this.resetDisabled !== undefined
        ? this.resetDisabled
        : this.isDirty !== true
    }
  },
  methods: {
    handleReset() {
      this.$store.dispatch('Config/resetClientAccountConfiguration')
      // Could be that the language was changed and not saved
      this.$rootMaybeChangeLocale(this.$store.getters['Config/getLocale'])
    },
    async handleSave() {
      this.loading = true
      await this.$emit('save')
      this.loading = false
    }
  }
}
</script>

<style scoped>
.settings-toolbar {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 2rem;
  background: white;
}
</style>
